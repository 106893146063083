// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-doc-template-js": () => import("./../../../src/templates/docTemplate.js" /* webpackChunkName: "component---src-templates-doc-template-js" */)
}

